export const strings = {
  en: {
    fundedTimes: ({ times = 0 }) => `Funded <b>${times}</b> ${times > 1 ? "times" : "time"} so far`,
    learnMore: () => `Learn more`,
    seeAll: () => `See All`,
  },
  fr: {
    fundedTimes: ({ times = 0 }) => `Financé <b>${times}</b> fois pour l'instant`,
    learnMore: () => `En savoir plus`,
    seeAll: () => `Voir tout`,
  },
  de: {
    fundedTimes: ({ times = 0 }) => `<b>${times}</b> Mal finanziert`,
    learnMore: () => `Mehr erfahren`,
    seeAll: () => `Alle anzeigen`,
  },
  es: {
    fundedTimes: ({ times = 0 }) => `Financiado <b>${times}</b> ${times > 1 ? "vez" : "veces"}`,
    learnMore: () => `Saber más`,
    seeAll: () => `Ver Todo`,
  },
  it: {
    fundedTimes: ({ times = 0 }) => `Finanziato <b>${times}</b> ${times > 1 ? "volta" : "volte"}`,
    learnMore: () => `Scopri di più`,
    seeAll: () => `Vedi Tutto`,
  },
  pl: {
    fundedTimes: ({ times = 0 }) => `Udzielono wsparcia <b>${times}</b> ${times > 1 ? "razy" : "raz"}`,
    learnMore: () => `Dowiedz się więcej`,
    seeAll: () => `Zobacz wszystko`,
  },
};
